.container {
  margin-bottom: 2.5rem;
}

.items {
  list-style: none;
  padding-left: 0;
}

.item {
  font-family: var(--heading-font);
  font-size: 1.25rem;
  line-height: 1.4;
  font-weight: 600;
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 4px 0;
  border-bottom: 2px solid #bad3ea;

  svg {
    display: block;
    min-width: 14px;
    width: 14px;
    max-height: 56px;
    margin-right: 12px;
    float: left;
    @media screen and (min-width: 992px) {
      max-height: 48px;
    }
  }



  @media screen and (min-width: 992px) {
    max-height: 48px;
    font-size: 1.25rem;
  }
}

.link {
  display: -webkit-box;
  display: -moz-box;
  color: var(--title-color);

  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;


  &:hover {
    color: var(--title-color);
    text-decoration: none;
    opacity: 0.8;
  }
}
